import { Directive, HostBinding, OnInit } from '@angular/core';

@Directive({
  selector: '[appViewportHeight]'
})
export class ViewportHeightDirective implements OnInit {
  @HostBinding('style.height.px') height!: number;

  ngOnInit() {
    this.height = window.innerHeight;
    window.addEventListener('resize', () => {
      this.height = window.innerHeight;
    });
  }
}
