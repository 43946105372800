import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {UserAuthInfo} from "./models/user-auth-info";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'finderwise-ui';

  private userAuthInfoSubscription!: Subscription;

  public userAuthInfoReference!: UserAuthInfo | null;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let token = params['token'];
      if (token) {
        let userAuthInfo: UserAuthInfo = {
          givenName: params['givenName'],
          email: params['email'],
          profilePictureUrl: params['profilePictureUrl'],
          token: params['token']
        };

        this.authService.setUserAuthInfo(userAuthInfo);
        this.removeTokenFromUrl();
      }
    });
    // Subscribe to the token Observable
    this.userAuthInfoSubscription = this.authService.userAuthInfo$.subscribe(userAuthInfo => {
      this.userAuthInfoReference = userAuthInfo;
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.userAuthInfoSubscription) {
      this.userAuthInfoSubscription.unsubscribe();
    }
  }

  removeTokenFromUrl() {
    this.router.navigate([], {
      queryParams: {
        token: null,
        givenName: null,
        email: null,
        profilePictureUrl: null
      },
      queryParamsHandling: 'merge'
    });
  }
}
