import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ChatMessage} from "../models/chat-message";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ChatApiService {
  private chatApiUrl = environment.chatApiUrl; // replace with your API URL

  constructor(private http: HttpClient, private authService: AuthService) { }

  sendMessages(messages: ChatMessage[]): Observable<string> {

    const httpOptions = { // TODO: Move this logic to httpService
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.authService.getAuthToken()
      }),
      responseType: 'text' as 'text'
    };
    const transformedMessages = messages.map(message => ({
      ...message,
      text: message.text.getValue() // BehaviorSubject to string
    }));

    const payload = {
      messages: transformedMessages
    };
    return this.http.post(this.chatApiUrl, payload, httpOptions);
  }
}
