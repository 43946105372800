import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import {NgOptimizedImage} from "@angular/common";
import { LoginComponent } from './login/login.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { MessageComponent } from './conversation/message/message.component';
import { PromptComponent } from './conversation/prompt/prompt.component';
import { ConversationShortcutComponent } from './conversation/conversation-shortcut/conversation-shortcut.component';
import { ChatMainComponent } from './chat/chat-main/chat-main.component';
import { MainSignInComponent } from './main/main-sign-in/main-sign-in.component';
import {RouterModule} from "@angular/router";
import { ViewportHeightDirective } from './directives/viewport-height.directive';
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpService} from "./services/http.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ClickOutsideDirective,
    MessageComponent,
    PromptComponent,
    ConversationShortcutComponent,
    ChatMainComponent,
    MainSignInComponent,
    ViewportHeightDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgOptimizedImage,
    BrowserModule,
    RouterModule.forRoot([]),
    FormsModule,
    HttpClientModule
  ],
  providers: [AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
