import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ChatMessage} from "../models/chat-message";
import {AuthService} from "./auth.service";
import {ChatApiService} from "./chat-api.service";


@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private messages: ChatMessage[] = [];
  private messagesSubject = new BehaviorSubject<ChatMessage[]>(this.messages);
  public messages$ = this.messagesSubject.asObservable();

  constructor(private authService: AuthService, private chatApiService: ChatApiService) {
  }

  addUserMessage(messageContent: string) {

    const message = this.createMessage(messageContent, false);
    this.addMessage(message);

    // Send message to the server
    this.sendConversationToServer();
  }

  private sendConversationToServer() {
    // Add temporary bot message.
    const botMessage = this.createMessage('', true);
    this.addMessage(botMessage);
    this.chatApiService.sendMessages(this.messages).subscribe(botOutput => {
      let lastMessage = this.messages[0];
      if (!lastMessage.bot) {
        const botMessage = this.createMessage(botOutput, true);
        this.addMessage(botMessage);
      } else {
        (lastMessage.text as BehaviorSubject<string>).next(botOutput);
        this.messagesSubject.next(this.messages);
      }
    });
  }

  private createMessage(messageContent: string, isBot: boolean) {
    let pictureUrl = isBot ? 'assets/svg/bot_icon.svg' : this.authService.getUserProfilePictureUrl();
    const message: ChatMessage = {
      bot: isBot,
      text: new BehaviorSubject<string>(messageContent),
      pictureUrl: pictureUrl,
      timestamp: this.createTimestamp(),
    };
    return message;
  }

  private createTimestamp() {
    const now = new Date();
    const isoString = now.toISOString();
    const nanoseconds = now.getMilliseconds().toString().padEnd(6, '0');
    return isoString.replace('Z', nanoseconds + 'Z');
  }

  private addMessage(message: ChatMessage) {
    this.messages.unshift(message);
    this.messagesSubject.next(this.messages);
  }

  getMessages() {
    return this.messages$;
  }

  clearMessages() {
    this.messages = [];
    this.messagesSubject.next(this.messages);
  }

  initializeChatConversation() {
    this.sendConversationToServer();
  }
}
