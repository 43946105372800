import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import {ChatMessage} from "../../models/chat-message";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  @Input("chatMessage")
  chatMessage!: ChatMessage;

  public typewriterText = '';
  private currentMessage = '';
  private i = 0;
  private speed = 15;

  private textSubscription!: Subscription;

  ngOnInit() {
    this.textSubscription = this.chatMessage.text.subscribe(newText => {
      this.currentMessage = this.formatStringToHtml(newText);
      if (this.chatMessage.bot) {
        this.startTypewriterEffect();
      } else {
        this.typewriterText = this.currentMessage;
      }
    });
  }

  ngOnDestroy() {
    if (this.textSubscription) {
      this.textSubscription.unsubscribe();
    }
  }

  formatStringToHtml(inputString: string | null) {
    if (!inputString) {
      return '';
    }
    return inputString
      .replace(/\n/g, '<br/>')
      .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  }

  startTypewriterEffect() {
    this.i = 0;
    this.typewriterText = '';
    this.typeWriter();
  }

  typeWriter() {
    if (this.i < this.currentMessage.length) {
      // Check if the current character is the start of a tag
      if (this.currentMessage.charAt(this.i) === '<') {
        // Find the end of the tag
        const tagCloseIndex = this.currentMessage.indexOf('>', this.i);

        // If we found the end of the tag, add the whole tag at once
        if (tagCloseIndex > this.i) {
          this.typewriterText += this.currentMessage.substring(this.i, tagCloseIndex + 1);
          this.i = tagCloseIndex + 1;
        } else {
          // If we didn't find the end of the tag, just add the current character
          this.typewriterText += this.currentMessage.charAt(this.i);
          this.i++;
        }
      } else {
        // If the current character is not the start of a tag, add it as normal
        this.typewriterText += this.currentMessage.charAt(this.i);
        this.i++;
      }

      // Randomize the speed to emulate human typing
      const minSpeed = 1;  // The minimum delay (in milliseconds)
      const maxSpeed = 1;  // The maximum delay (in milliseconds)
      const speed = Math.random() * (maxSpeed - minSpeed) + minSpeed;

      setTimeout(() => this.typeWriter(), speed);
    }
}
}
