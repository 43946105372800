import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../environments/environment';
import {CookieService} from "ngx-cookie-service";
import {BehaviorSubject} from "rxjs";
import {UserAuthInfo} from "../models/user-auth-info";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private renderer: Renderer2;

  private userAuthInfoSubject: BehaviorSubject<UserAuthInfo | null> = new BehaviorSubject<UserAuthInfo | null>(null);
  userAuthInfo$ = this.userAuthInfoSubject.asObservable();

  constructor(rendererFactory: RendererFactory2, private cookieService: CookieService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public initializeAuthService() {
    this.loadGoogleOneTap();
    this.loadTokenCookie();
  }

  loadGoogleOneTap() {
    const script = this.renderer.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    this.renderer.listen(script, 'load', () => {
      this.initializeGoogleSignIn("399180792004-0o6slhv7pp77rfqhh0titg6hlke2b712.apps.googleusercontent.com");
    });
    this.renderer.appendChild(document.body, script);
  }

  handleCredentialResponse(response: any) { // TODO: is necessary?
    // this function would handle the JWT token, possibly like this:
    const token = response.credential;
    // store the token in an HTTP only cookie or local storage
    // then, use the token for user authentication
    console.log("Login successful!!!"); // Log the token to the console
    console.log(token); // Log the token to the console
  }

  initializeGoogleSignIn(clientId: string) {
    // Documentation: https://developers.google.com/identity/gsi/web/reference/js-reference
    const google: any = (window as any)['google'];
    if (google && google.accounts && google.accounts.id) {
      google.accounts.id.initialize({
        client_id: clientId,
        ux_mode: "redirect",
        login_uri: environment.redirectUrl,  // Add your redirect URL here
        callback: (response: any) => this.handleCredentialResponse(response) // probably not needed for redirect
      });
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }  // customization attributes
      );
      //google.accounts.id.prompt(); // also display the One Tap dialog
    }
  }

  private setCookie(name: string, value: string, days: number) {
    this.cookieService.set(name, value, days);
  }

  private getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  private deleteCookie(name: string) {
    this.cookieService.delete(name);
  }

  setUserAuthInfo(userAuthInfo: UserAuthInfo | null) {
    if (userAuthInfo) {
      this.setCookie('givenName', userAuthInfo.givenName, 1);
      this.setCookie('email', userAuthInfo.email, 1);
      this.setCookie('profilePictureUrl', userAuthInfo.profilePictureUrl, 1);
      this.setCookie('token', userAuthInfo.token, 1); // Save userAuthInfo to a cookie. Expires in 1 day.
      this.userAuthInfoSubject.next(userAuthInfo);
    }
  }

  private loadTokenCookie() {
    let userAuthInfo: UserAuthInfo = {
      givenName: this.getCookie('givenName'),
      email: this.getCookie('email'),
      profilePictureUrl: this.getCookie('profilePictureUrl'),
      token: this.getCookie('token')
    };
    this.setUserAuthInfo(userAuthInfo);
  }

  logout() {
    this.deleteCookie('givenName');
    this.deleteCookie('email');
    this.deleteCookie('profilePictureUrl');
    this.deleteCookie('token');
    this.userAuthInfoSubject.next(null);
  }

  getUserProfilePictureUrl() {
    return this.getCookie('profilePictureUrl');
  }

  getAuthToken() {
    return this.getCookie('token');
  }
}
