import { Component } from '@angular/core';
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public isLoginDropdownVisible: boolean;

  constructor(private authService: AuthService) {
    this.isLoginDropdownVisible = false;
  }

  toggleLoginDropdown() {
    this.isLoginDropdownVisible = !this.isLoginDropdownVisible;
  }

  handleClickOutsideLogin() {
    this.isLoginDropdownVisible = false;
  }

  logout() {
    this.authService.logout();
  }

  getUserInfo() {
    return this.authService.userAuthInfo$;
  }
}
