import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ChatMessage} from "../../models/chat-message";
import {ChatService} from "../../services/chat.service";

@Component({
  selector: 'app-chat-main',
  templateUrl: './chat-main.component.html',
  styleUrls: ['./chat-main.component.scss']
})
export class ChatMainComponent implements OnInit {

  @Input("chatMessages") chatMessages: Array<ChatMessage> = [];

  constructor(private authService: AuthService, private chatService: ChatService) {
  }

  getUserInfo() {
    return this.authService.userAuthInfo$;
  }

  ngOnInit(): void {
    this.getMessages();
    this.chatService.initializeChatConversation();
  }

  getMessages(): void {
    this.chatService.getMessages().subscribe((data: Array<ChatMessage>) => {
      this.chatMessages = data;
    });
  }
}
