import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HttpService implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            const wwwAuthenticate = error.headers.get('WWW-Authenticate');
            if (wwwAuthenticate && wwwAuthenticate.includes('invalid_token')) {
              if (wwwAuthenticate.includes('Jwt expired')) {
                console.log('JWT token has expired');
                // handle JWT expiration here
              } else {
                console.log('JWT token is invalid');
                // handle other JWT errors here
              }
              this.authService.logout();
              this.router.navigate([this.router.url]).then(() => {
                window.location.reload();
              });
            }
          }
          return throwError(error); // TODO: Fix deprecated
        })
      );
  }
}
