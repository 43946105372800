import {AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {ChatService} from "../../services/chat.service";

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements AfterViewInit {
  @ViewChild('myTextarea') myTextarea!: ElementRef;

  @Output() onSendMessage = new EventEmitter<string>();
  public promptText: string = '';

  private initialHeight: number = 68; // Defined the initial height to be 68px

  constructor(private chatService: ChatService) {}

  sendChatMessage(): void {
    let promptTextValue = this.getPromptTextValue();
    if (promptTextValue.length > 0) {
      this.promptText = '';
      this.revertToInitialHeight(this.myTextarea.nativeElement);
      this.onSendMessage.emit(promptTextValue);
      this.chatService.addUserMessage(promptTextValue);
    }
  }

  private getPromptTextValue() {
    return this.promptText.replace(/^[ \t]+|[ \t]+$/gm, '');
  }

  ngAfterViewInit(): void {
    this.myTextarea.nativeElement.style.height = `${this.initialHeight - 20}px`; // Set initial height when textarea loads
    this.focus();
  }

  public focus() {
    this.myTextarea.nativeElement.focus();
  }

  adjustHeight(event: any): void {
    const target = event.target as HTMLTextAreaElement;
    this.adjustTextAreaHeight(target);
  }

  private adjustTextAreaHeight(target: HTMLTextAreaElement) {
    const isMoreThanOneLine = this.isContentMoreThanOneLine(target);
    this.resetHeight(target);

    if (isMoreThanOneLine) {
      this.expandHeightToContent(target);
    } else {
      this.revertToInitialHeight(target);
    }
  }

  private resetHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'auto';
  }

  private isContentMoreThanOneLine(textarea: HTMLTextAreaElement): boolean {
    const style = window.getComputedStyle(textarea);
    const verticalBorder = parseFloat(style.getPropertyValue('border-top-width')) + parseFloat(style.getPropertyValue('border-bottom-width'));

    return textarea.scrollHeight > (this.initialHeight  - verticalBorder);
  }

  private expandHeightToContent(textarea: HTMLTextAreaElement): void {
    let scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${scrollHeight}px`;
  }

  private revertToInitialHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = `${this.initialHeight - 20}px`;
  }

  isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  onKeyDownSendChatMessage(event: Event) {
    if (this.isMobileDevice()) {
      return;
    }
    if (event instanceof KeyboardEvent) {
      const keyboardEvent = event as KeyboardEvent;
      keyboardEvent.preventDefault();
    }
    this.sendChatMessage();
  }

  onButtonPressSendChatMessage(event: MouseEvent) {
    this.sendChatMessage();
  }
}
