import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import versionInfo from '../../../environments/version-info.json';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-main-sign-in',
  templateUrl: './main-sign-in.component.html',
  styleUrls: ['./main-sign-in.component.scss'],
  providers: [DatePipe]
})
export class MainSignInComponent implements OnInit{
  gitSHA!: string;
  buildTime!: string | null;

  constructor(private authService: AuthService, private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.authService.initializeAuthService();
    this.gitSHA = (versionInfo as any).gitSHA;
    this.buildTime = this.transformDate();
  }

  private transformDate() {
    let buildTime = (versionInfo as any).buildTime;
    if (buildTime) {
      const date = new Date(buildTime); // create Date object from buildTime
      return this.datePipe.transform(date, 'MMMM d, y, h:mm a'); // format the date to a localized string
    } else {
      return 'N/A';
    }

  }
}
