import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-conversation-shortcut',
  templateUrl: './conversation-shortcut.component.html',
  styleUrls: ['./conversation-shortcut.component.scss']
})
export class ConversationShortcutComponent {
  @Input("shortcutText") shortcutText!: string;

}
